<template>
  <div  class="loginSection">
    <loader v-show="isLoading"></loader>
    <div  class="layoutBorder"  v-show="!isLoading">
      <div class="layoutHeader">
        <h4>Востановление пароля</h4>
      </div>
      <div class="layoutBody">
        <div class="inputBox">
          <input type="tel" id="login2"
                 v-model.trim="DlCode"
                 >
          <label for="login2">Логин(Номер договора)</label>
          <div v-show="errorlogin.length > 0" v-html="errorlogin" class="errMsg"></div>
          <p class="stxt">На ваш номер телефона будет отправлено СМС-сообщение с кодом подтверждения</p>
        </div>
        <div v-show="responseError.length > 0" v-html="responseError" class="errMsg"></div>
      </div>

      <div class="layoutFooter">

        <div id="navLoginWrap"  v-show="!isLoading">
          <router-link :class="'linkColor'" to="/login/nsb">Назад</router-link>
        </div>
        <button v-on:click="sendAction" class="submitButton" v-show="!isLoading">Отправить смс</button>
      </div>

    </div>

  </div>

</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: "nsbRecoveryForm",
    data(){
      return {
        isLoading:false,
        errorlogin: '',
        responseError:'',
      }
    },
    methods:{
      checkLogin() {
        if (this.$bikeFunction.correctDlCode(this.DlCode)) {
          this.errorlogin = '';
          return true;
        } else {
          this.errorlogin = 'Некорректный логин';
          return false;
        }
      },
      sendAction(){
        if (this.checkLogin()){
          this.isLoading = true;
          this.$external.universalRequest({
            externalType: 'zita',
            action: 'recoveryPassNsb',
            Demandid: this.DlCode
          }).then((res) => {
            this.$store.commit('setUserID', res.UserID);
            localStorage.setItem('countdown', '60');
            this.$router.push('/login/ConfirmationCode');
          })
          .catch((res) => {
            if ( res.errorCode > 0 || res.errorCode !== undefined ) {
              alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
            }
            try {
              this.responseError = res.errorMsg;
            } catch (e) {
              this.responseError = JSON.stringify(res);
            }
          })
          .finally(()=>{
            this.isLoading = false;
          });
        }

      }
    },
    computed:{
      DlCode:{
        get(){
          return this.loginVuex;
        },
        set(value){
          this.$store.commit('setDlCode', value)
        }
      },
      ...mapState({
        loginVuex: state => state.auth.DlCode,
        passwordVuex: state => state.password,
      })
    },
    components: {
      loader: () => import('@/components/other/loader'),
    },
    mounted() {
      this.isLoading = false;
      this.$myApi.checkVersion();
    }
  }
</script>

<style scoped>

</style>
